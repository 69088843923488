import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useState } from "react";
import { API_PATCH, DBRequest, useConfig } from "../../../../api/api";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WPEToDoElementPopup from "./WPEToDoElementPopup";
import { ProjectUserStack } from "../ProjectUserStack";

export const WPEToDoElement = ({
  element,
  onChange,
  wpeId,
  onDelete,
  className,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();

  const [isProcessing, setIsProcessing] = useState(false);
  const [dataCollection, setDataCollection] = useState(element);
  const [openPopup, setOpenPopup] = useState(false);

  function handleToggleComplete() {
    if (isProcessing) return;
    element.status ? (dataCollection.status = 0) : (dataCollection.status = 1);
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH(dataCollection),
      onResponse: handleResponse,
      onLoading: setIsProcessing,
    });
  }

  function handleResponse(res) {
    onChange(res.data);
  }

  const priorityColorMap = {
    0: colors.grey,
    1: colors.success,
    2: colors.warning,
    3: colors.error,
  };

  return (
    <Box className={`flex relative ${className}`}>
      <Box
        className="w-[10px] h-full absolute rounded-l-lg"
        sx={{ background: priorityColorMap[element.priority] }}
      />
      <Box
        className={`flex items-center gap-2 rounded-lg  px-3 py-1 w-full `}
        sx={{
          backgroundColor: colors.bgInput,
          border: "1px solid transparent",
        }}
      >
        <Box>
          <IconButton onClick={handleToggleComplete}>
            {isProcessing ? (
              <span
                className="loader "
                style={{ width: "20px", height: "20px" }}
              />
            ) : element.status ? (
              <TaskAltIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </IconButton>
        </Box>
        <Box
          className="w-full h-full items-center flex"
          onClick={() => setOpenPopup(true)}
        >
          <Typography className="w-full" variant="h5" fontWeight={500}>
            {element.name}
          </Typography>
          <Box className="flex justify-end">
            <ProjectUserStack
              editmode={false}
              users={element.users}
              noLabel={true}
            />
          </Box>
        </Box>
        <WPEToDoElementPopup
          element={element}
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onResponse={handleResponse}
          onDelete={onDelete}
        />
      </Box>
    </Box>
  );
};
