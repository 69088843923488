import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Transactions from "./Transactions";
import { useNavigate, useParams } from "react-router-dom";
import BankAccount from "./BankAccount";
import useFetch from "../../api/useFetch";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";

export default function ShowBankAccount() {
  const { t } = useTranslation();

  const scrollContainerRef = useRef(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const { apiData } = useFetch(`bankaccounts/${id}`);

  return (
    apiData && (
      <Box
        className="overflow-y-auto h-full w-full"
        ref={scrollContainerRef}
        sx={{
          maxHeight: "calc(100vh - 64px - 14px)",
        }}
      >
        <Header title={t("Bank Accounts")} />

        <Box
          className="rounded-xl m-auto backdrop-blur-lg mb-4"
          sx={{
            maxWidth: "900px",
          }}
        >
          <BankAccount
            onClick={() => navigate("/bank-accounts")}
            bank={apiData.data}
          />
        </Box>
        <Transactions
          bankAccount={apiData.data}
          scrollContainerRef={scrollContainerRef}
        />
      </Box>
    )
  );
}
