import { useEffect, useState } from 'react';
import { API_POST, DBRequest, useConfig } from '../../api/api';
import ExoStepper, { FinalPage } from '../../components/exo/ExoStepper';
import SelectPositions from '../../components/select/SelectPositions';
import StepDelivery from '../../components/steps/StepDelivery';
import StepCustomStartUp from '../../components/steps/StepCustomStartUp';
import * as Yup from 'yup';
import StepOrder from '../steps/StepOrder';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useFetch from '../../api/useFetch';
import { StepTemplate } from '../steps/StepTemplate';
import StepPositionsOrder from '../steps/StepPositionsOrder';
import { PositionImage } from '../exo/positions/collapse-modules/PositionImage';
import { PositionDescription } from '../exo/positions/collapse-modules/PositionDescription';
import { PositionNumber } from '../exo/positions/collapse-modules/PositionNumber';
import { PositionText } from '../exo/positions/collapse-modules/PositionText';
import { PositionCheckbox } from '../exo/positions/collapse-modules/PositionCheckbox';
import { PositionProgressPaymentCalculation } from '../exo/positions/collapse-modules/PositionProgressPaymentCalcilation';
import { PositionCopy } from '../exo/positions/collapse-modules/PositionCopy';

const NewOrderInvoice = ({
  invoices,
  setInvoices,
  onClose,
  startData = {},
  onUpdate = () => {},
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  const [steps, setSteps] = useState(generateSteps());

  const requiredValidationSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    date: Yup.string().required(t('Date is required')),
    deliveryDateEnd: Yup.string().required(t('Date is required')),
    paymentDeadlineInDays: Yup.number().required(
      t('Payment Deadline in Days is required')
    ),
  });

  function generateSteps() {
    var requiredFields = [
      {
        label: t('Title'),
        type: 'text',
        key: 'title',
      },
      {
        label: `${t('Project')} (${t('optional')})`,
        type: 'project',
        key: 'project',
        apiUrl: 'projects',
      },
      {
        label: t('Invoice Date'),
        type: 'date',
        key: 'date',
      },
      {
        label: t('Delivery Date'),
        type: 'date',
        key: 'deliveryDate',
      },
      {
        label: t('Payment Deadline in Days'),
        type: 'text',
        key: 'paymentDeadlineInDays',
      },
      {
        label: `${t('Note')} (${t('optional')})`,
        type: 'description',
        key: 'note',
        multiline: true,
      },
    ];

    if (config) {
      const useDeliveryDateRange = Boolean(
        parseInt(config.invoice.useDeliveryDateRange)
      );
      requiredFields = [
        {
          label: t('Title'),
          type: 'text',
          key: 'title',
        },
        {
          label: t('Invoice Date'),
          type: 'date',
          key: 'date',
        },
        {
          label: useDeliveryDateRange
            ? t('Delivery Date Range')
            : t('Delivery Date'),
          type: useDeliveryDateRange ? 'dateRange' : 'date',
          key: useDeliveryDateRange ? 'deliveryDate' : 'deliveryDateEnd',
        },
        {
          label: t('Payment Deadline in Days'),
          type: 'text',
          key: 'paymentDeadlineInDays',
        },
        {
          label: `${t('Note')} (${t('optional')})`,
          type: 'description',
          key: 'note',
          multiline: true,
        },
      ];
    }

    const optionalFields = [
      {
        label: `${t('Discount')} (${t('optional')})`,
        type: 'checkbox',
        key: 'discount',
      },
      {
        label: `${t('Discount Rate')} (${t('optional')})`,
        type: 'number',
        key: 'discountRate',
        decimalPlaces: 2,
      },
      {
        label: `${t('Discount Days To Pay')} (${t('optional')})`,
        type: 'number',
        key: 'discountDaysToPay',
        decimalPlaces: 0,
      },
      {
        label: `${t('Images')} (${t('optional')})`,
        type: 'images',
        key: 'additionalFiles',
        maxItems: '15',
        maxSizeKB: '3000',
      },
    ];

    return [
      {
        label: t('Order'),
        valid: false,
        content: ({
          updateDataCollection,
          activeStep,
          setActiveStep,
          step,
          dataCollection,
          updateValidation,
        }) => (
          <StepOrder
            onClose={onClose}
            dataCollection={dataCollection}
            updateDataCollection={updateDataCollection}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            step={step}
            updateValidation={updateValidation}
          />
        ),
      },
      {
        label: t('Template'),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
          setActiveStep,
          activeStep,
          setSteps,
        }) => (
          <StepTemplate
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            templateType="invoice"
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setSteps={setSteps}
          />
        ),
      },
      {
        label: t('Info'),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepCustomStartUp
            fields={requiredFields}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            validationSchema={requiredValidationSchema}
          />
        ),
      },
      {
        label: `(${t('Optional')})`,
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepCustomStartUp
            fields={optionalFields}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
          />
        ),
      },
      {
        label: t('Order Positions'),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepSelectOrderPositions
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
          />
        ),
      },
      {
        label: t('Custom Positions'),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepPositionsOrder
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            collapseModules={[
              { component: PositionImage, key: 'image', label: 'Image' },
              {
                component: PositionDescription,
                key: 'description',
                label: 'Description',
              },
              {
                component: PositionNumber,
                key: 'taxRate',
                label: 'Tax Rate',
                unit: '%',
                decimalPlaces: 0,
              },
              {
                component: PositionText,
                key: 'unit',
                label: 'Unit',
              },
              {
                component: PositionCopy,
                label: 'Duplicate position',
              },
              {
                component: PositionCheckbox,
                key: 'isOrderPosition',
                label: 'Settle from order',
              },
              {
                component: PositionProgressPaymentCalculation,
                key: 'price',
                label: 'Progress Payment Calculation',
              },
            ]}
            imageType={`INVOICE_ATTACHMENT`}
            imageApiFilter={
              startData && startData.id
                ? `orderImages[has]=${startData.id}`
                : `orderImages[doesnothave]=0`
            }
          />
        ),
      },

      {
        label: t('Delivery'),
        content: ({
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepDelivery
            useInvoiceMail={true}
            updateDataCollection={updateDataCollection}
            dataCollection={dataCollection}
            updateValidation={updateValidation}
            defaultRecipientMailKey="isInvoiceMail"
            allowMailContent={true}
          />
        ),
      },
    ];
  }

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);
      DBRequest({
        config,
        path: 'invoices',
        method: API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={invoices} setData={setInvoices} />
    ), // component with final operations and the feedback
  };

  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    if (!config) return;
    setSteps(generateSteps());
    const today = moment().format();

    const useDeliveryDateRange = Boolean(
      parseInt(config.invoice.useDeliveryDateRange)
    );
    const newDefaultData = {
      date: today,
      deliveryDateStart: useDeliveryDateRange ? today : null,
      deliveryDateEnd: today,
      paymentDeadlineInDays: config.invoice.paymentDeadlineInDays,
      discountRate: config.invoice.discountRate,
      discountPaymentDeadlineInDays:
        config.invoice.discountPaymentDeadlineInDays,
      useDeliveryDateRange,
      sourceType: 'order',
    };
    setDefaultData(newDefaultData);
  }, [config]);

  return (
    <ExoStepper
      draftType={'order-invoice'}
      startStep={0}
      startDataCollection={defaultData}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
      message={t('Creating Invoice')}
      label={t('New Order Based Invoice')}
      previewDocument={'invoice'}
    />
  );
};

const StepSelectOrderPositions = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
}) => {
  //load positions
  const { apiData, isLoading } = useFetch(`orders/${dataCollection.order.id}`);

  function handleSelectedPositions(positions) {
    var newPositions = dataCollection.positions || [];

    positions.forEach((element) => {
      newPositions.push({
        ...element,
        isOptional: false,
        orderPositionId: element.id,
        isOrderPosition: true,
      });
    });

    updateDataCollection({ positions: sortByPosition(newPositions) });
  }
  useEffect(() => {
    updateValidation(true);
  }, []);

  function sortByPosition(arr) {
    return arr.sort((a, b) => {
      const posA = a.position.split('.').map(Number);
      const posB = b.position.split('.').map(Number);

      // Compare each part of the position
      for (let i = 0; i < Math.max(posA.length, posB.length); i++) {
        const partA = posA[i] || 0; // Default to 0 if part is undefined
        const partB = posB[i] || 0; // Default to 0 if part is undefined

        if (partA !== partB) {
          return partA - partB;
        }
      }

      // If all parts are equal
      return 0;
    });
  }

  return (
    <SelectPositions
      positions={!isLoading && apiData.data.positions}
      isLoading={isLoading}
      onSelect={handleSelectedPositions}
    />
  );
};

export default NewOrderInvoice;
