import { Box, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExoForm from "../../ExoForm";
import DateTimeLabel from "../../../label/DateTimeLabel";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import {
  API_DELETE,
  API_PATCH,
  DBRequest,
  useConfig,
} from "../../../../api/api";
import { ProjectUserStack } from "../ProjectUserStack";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const WPEToDoElementPopup = ({
  element,
  open,
  onClose,
  onResponse,
  onDelete,
}) => {
  const { t } = useTranslation();

  const config = useConfig();

  const [users, setUsers] = useState(element.users);

  const fields = [
    { label: t("Title"), key: "name", type: "text" },
    { label: t("Description"), key: "description", type: "richText" },
    {
      label: t("Priority"),
      key: "priority",
      type: "customSelect",
      options: [
        { label: t("Default"), value: 0 },
        { label: t("Low"), value: 1 },
        { label: t("Medium"), value: 2 },
        { label: t("High"), value: 3 },
      ],
    },
    { label: t("Deadline"), key: "deadline", type: "date" },
    { label: t("File"), key: "file", type: "image" },
  ];

  function handleSubmit(newTask) {
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH({ ...newTask, users: users }),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    onResponse(data);
    onClose();
  }

  function handleMemberChange(newMembers) {
    setUsers(newMembers.users);
  }

  function handleDelete() {
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(element),
    });
  }

  return (
    <Dialog className="relative" open={open} onClose={onClose}>
      <Box className="w-[50vw] m-5 mb-3 relative">
        <Box className="flex items-center justify-between">
          <Box>
            <Typography variant="h3">{t("Edit Task")}</Typography>
            <Tooltip
              title={t("Date created")}
              className="flex gap-1 items-center my-2"
            >
              <MoreTimeIcon fontSize="small" />
              <Typography variant="h6">
                <DateTimeLabel dateTime={element.createdAt} />
              </Typography>
            </Tooltip>
          </Box>
          <ProjectUserStack
            changeApi={`tasks/${element.id}/members`}
            editmode={true}
            users={users || []}
            api={"users"}
            onChange={handleMemberChange}
            noLabel={true}
          />
        </Box>
        <ExoForm
          fields={fields}
          startDataCollection={element}
          onSubmit={handleSubmit}
        />
        <Box className="absolute -left-2 bottom-1">
          <IconButton onClick={handleDelete}>
            <DeleteIcon className />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WPEToDoElementPopup;
